import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'
import Link from '../utils/link'
import JobCard from '../components/cards/job'
import DownArrow from '../assets/images/scroll-down-arrow.svg'
import Fade from 'react-reveal/Fade'

class JobTemplate extends Component {

  render() {
    let job = this.props.data.wpJob
    let extras = this.props.data.extras;
    let jobsSidebarTitle = this.props.data.wp.siteOptions.settings.jobsSidebarTitle
    let location = ( job.locations && job.locations.nodes && job.locations.nodes.length > 0 ) ? job.locations.nodes[0].name : null;
    let jobTitle = ( job.jobTitles && job.jobTitles.nodes && job.jobTitles.nodes.length > 0 ) ? job.jobTitles.nodes[0].name : null;

    return (
      <>
        <Seo title={he.decode(job.seo.title)} bodyClass={job.slug} description={job.seo.metaDesc} />
        <section className='job'>
          <div className='job__inner'>

            <div className='job__content'>

              <div className='job__heading'>
                <p>Job description</p>
                <h2>{ job.title }</h2>
              </div>

              <div className='job__title'>
                
                <p> 

                { jobTitle &&
                  <span>Job title : { jobTitle }</span>
                }

                { location &&
                  <span>Location : { location }</span>
                }

                </p> 

              </div>

              <div className='job__details'>

                <div className='content'>

                  <div dangerouslySetInnerHTML={{ __html: job.content}} />

                  <div className='job__button'>
                    <Link className='btn' to={job.acf.jobLink}>Apply for job</Link>
                  </div>

                </div>

                <div className='job__side-menu'>

                  <p>{jobsSidebarTitle}</p> 

                    {extras.edges.map((el, i) => (
                      <Fade bottom distance='30px'>
                        <div className='job__item' key={i}>
                          <JobCard slug='test-job' post_type='job' {...el.node} />
                        </div>
                      </Fade>
                    ))}

                </div>

              </div>

            </div>

          </div>
        </section>
      </>
    )
  }
}

export const jobQuery = graphql`
  query ($id: String!) {
    wpJob(id: {eq: $id}) {
      title
      slug
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      locations {
        nodes {
          id
          name
          slug
        }
      }
      jobTitles {
        nodes {
          id
          name
          slug
        }
      }
      jobTypes {
        nodes {
          id
          name
          slug
        }
      }
      acf {
        jobLink
      }
    }
    extras: allWpJob(limit: 2, filter: {id: {ne: $id}}) {
      edges {
        node {
          id
          title
          slug
          excerpt
        }
      }
    }
    wp {
      siteOptions {
        settings {
          jobsSidebarTitle
        }
      }
    }
  }
`

export default JobTemplate
